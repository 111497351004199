export const translationBG = {
  "estateTypes.apartment": "Апартамент",
  "estateTypes.garage": "Гараж",
  "estateTypes.office": "Офис",
  "estateTypes.store": "Търг. помещение",
  "estateTypes.parking_space": "Паркомясто",
  "estateTypes.parking space": "Паркомясто",
  "estateTypes.studio": "Студио",
  "estateTypes.shop": "Магазин",
  "estateTypes.atelier": "Ателие",
  "estateTypes.restaurant": "Ресторант",
  "estateTypes.gym": "Фитнес",
  "estateTypes.laboratory": "Лаборатория",
  "estateTypes.other": "Друго",
  "paymentMethods.null": "Без значение",
  "paymentMethods.bank": "Банков превод",
  "paymentMethods.cash": "В брой",
  "paymentMethods.card": "Kарта",
  "paymentMethods.epay": "ePay",
  "paymentMethods.easypay": "EasyPay",
  "paymentMethods.number": "Epay/Easypay номер",
  "paymentMethods.balance": "Чрез баланс",
  "chargeStatus.null": "Без значение",
  "chargeStatus.unsettled": "Очаква плащане",
  "chargeStatus.settled": "Платено",
  "chargeStatus.partial": "Частично платено",
  "ticketStates.all": "Всички",
  "ticketStates.open": "Отворен",
  "ticketStates.in_progress": "Работи се",
  "ticketStates.resolved": "Разрешен",
  "ticketStates.invalid": "Отхвърлен",
  "ticketStates.closed": "Завършен",
  "invoiceStates.pending": "Очаква плащане",
  "invoiceStates.paid": "Платена",
  "ticketTypes.null": "Всички",
  "ticketTypes.technical": "Технически проблем",
  "ticketTypes.other": "Административно-финансов или друг проблем",
  "transactionTypes.null": "Всички",
  "transactionTypes.general": "Общи",
  "transactionTypes.income": "Приход",
  "transactionTypes.expense": "Разход",
  "estateCustomerTypes.tenant": "наемател",
  "estateCustomerTypes.owner": "собственик",
  "ticketUserTypes.assignee": "Отговорник",
  "userActions.edit": "Промени",
  "userActions.delete": "Изтрий",
  "userActions.respond": "Отговори",
  "userActions.comment": "Коментирай",
  "labels.date": "Дата",
  "labels.createdAt": "Дата на създаване",
  "labels.title": "Заглавие",
  "labels.status": "Състояние",
  "labels.comments": "Коментари",
  "labels.edited": "Редактиран",
  "labels.id": "Идентификатор",
  "labels.name": "Име",
  "labels.block": "Обект",
  "labels.author": "Автор",
  "labels.assignee": "Отговорник",
  "labels.content": "Съдържание",
  "labels.description": "Описание",
  "labels.detailed_description": "Подробно описание",
  "labels.published": "Публикуван",
  "labels.not_published": "Не е публикуван",
  "labels.none": "Няма",
  "labels.incomes": "Приходи",
  "labels.expenses": "Разходи",
  "labels.amount": "Сума",
  "labels.source": "Източник",
  "labels.customers": "Клиенти",
  "labels.payments": "Вноски",
  "labels.payment": "Вноска",
  "labels.action": "Действие",
  "labels.category": "Категория",
  "labels.resource": "Ресурс",
  "labels.payment_number": "Плащане Номер",
  "labels.user": "Потребител",
  "labels.chosen_funds": "Избрани Каси",
  "labels.type": "Тип",
  "labels.type_lowerc": "тип",
  "labels.all_funds": "Всички Каси",
  "labels.select_report_type": "Изберете тип на отчета",
  "labels.save": "Запази",
  "labels.multiply": "по",
  "labels.from": "от",
  "labels.transaction_categories": "Видове трансакции",
  "labels.add": "Добави",
  "labels.submit": "Запази",
  "labels.minimum": "мин.",
  "labels.total_amount": "Обща сума",
  "labels.total": "Общо",
  "labels.charge_amount_left": "Остатък за плащане",
  "labels.new_obligation": "Ново задължение към",
  "labels.obligation_change": "Промяна на задължение към",
  "labels.direction": "Направление",
  "labels.select_direction": "Изберете направление",
  "labels.toall": "Към Всички",
  "labels.month": "Месец",
  "labels.client_number": "Клиентски номер",
  "labels.deposited_amount": "Внесена сума",
  "labels.prepaid_amount": "Предплатена сума",
  "labels.paid_amount": "Платена сума",
  "labels.official_receipt_cap": "СЛУЖЕБЕН БОН",
  "labels.print": "Принтирай",
  "labels.amount_due": "Дължима сума",
  "labels.yes": "Да",
  "labels.yes_low": "да",
  "labels.no": "Не",
  "labels.and": "и",
  "labels.in": "в",
  "labels.here": "тук",
  "labels.names": "Имена",
  "labels.day": "ден",
  "labels.days": "дни",
  "labels.apply": "Приложи",
  "labels.remove": "Премахни",
  "labels.filters": "Филтри",
  "labels.search": "Търси",
  "labels.change": "Промяна",
  "labels.create": "Създаване",
  "labels.deletion": "Изтриване",
  "labels.delete": "Изтрий",
  "labels.close": "Затвори",
  "labels.forwards": "Напред",
  "labels.enter": "Влез",
  "labels.exit": "Изход",
  "labels.proceed": "Продължи",
  "labels.confirm_continue": "Сигурни ли сте, че искате да продължите ?",
  "labels.fund": "Каса",
  "labels.csv_file": "CSV файл",
  "labels.required_fields": "Необходими полета",
  "labels.expense_csv_fields": "Полета в CSV",
  "labels.error": "Грешка",
  "labels.from_date": "От дата",
  "labels.to_date": "До дата",
  "labels.all": "Всички",
  "labels.votes": "Гласове",
  "labels.transaction_categories_description":
    "Посочването на видове трансакции позволява създаването на отчети групирани по вид трансакция в раздел 'Управление -> Финанси'",
  "labels.enable_ticket_voting": "Гласуване за сигнали",
  "labels.enable_ticket_voting_description":
    "Включването на тази опция позволява на потребителите да гласуват за сигнали",
  "labels.vendor_id": "Клиентски номер",
  "labels.estate_info": "Информация за имот",
  "labels.unique_number": "Уникален номер",
  "labels.distributed_amount": "Разпределена сума",
  "labels.correspondent": "Кореспондент",
  "side.management": "Управление",
  "side.locations": "Обекти",
  "side.properties": "Имоти",
  "side.signals": "Сигнали",
  "side.dashboard": "Табло",
  "side.residents": "Обитатели",
  "side.accounting": "Счетоводство",
  "titles.full_report": "Пълен отчет",
  "titles.report_debt_deposits": "Отчет задължения и вноски",
  "titles.debts_income": "Задължения и приходи",
  "titles.cash_report": "Касов отчет",
  "titles.from_date": "От дата",
  "titles.to_date": "До дата",
  "placeholders.all_funds": "Всички каси",
  "placeholders.begin_writing_to_add": "Добавете нова стойност",
  "titles.date_of_obligation": "Дата на задължаване",
  "titles.duties_paid": "Платени задължения",
  "roles.admin": "администратор",
  "roles.staff": "персонал",
  "roles.user": "потребител",
  "blockdash.virt_dash": "Включено виртуално табло",
  "blockdash.auto_gen": "Включено автоматично генериране на месечни задължения",
  "blockdash.online_payments": "Включени онлайн плащания",
  "blockdash.notifications":
    "Включени автоматични нотификации за текущи задължения",
  "resources.null": "Всички",
  "resources.audit": "проследяване",
  "resources.billing-attribute": "атрибут за таксуване",
  "resources.billing-forecast": "счетоводна прогноза",
  "resources.billing-model": "модел за таксуване",
  "resources.billing-monthly-report": "месечен отчет",
  "resources.billing-summary": "счетоводно обобщение",
  "resources.block": "обект",
  "resources.board-post": "публикация",
  "resources.charge": "задължение",
  "resources.charge_to": "Задължение към",
  "resources.collection": "събиране",
  "resources.collection-stats": "статистики за събиране",
  "resources.estate": "имот",
  "resources.estate_capital": "Имот",
  "resources.estate-customers": "обитател",
  "resources.file": "документ",
  "resources.file-dir": "директория",
  "resources.note": "бележка",
  "resources.user-role": "Роля",
  "resources.billing-attribute-bulk-upsert": "множество атрибути за таксуване",
  "resources.fund-transaction": "трансакция",
  "resources.checkout": "плащане",
  "resources.bgn": "лв.",
  "resources.bgn_no_dot": "лв",
  "profile.remove_link": "Премахване на връзката между",
  "profile.remove_user_link":
    "Сигурни ли сте, че искате да премахнете връзката между потребител",
  "profile.and_property": "и имот",
  "profile.other_properties": "други имоти",
  "viber.quickacess_with": "Бърз достъп с",
  "viber.chatbot": "Viber чат бот",
  "viber.chatbot_info":
    "Чрез нашият Viber чат бот ще получавате актуална информация, \nсвързана с вашата етажна собственост и можете бързо и лесно да \nпроверите вашите текущи задължения.",
  "viber.scan_code":
    "Сканирайте кода за да стартирате чат и следвайте инструкциите.",
  "viber.clickhere": "Кликнете тук",
  "viber.click_if_app":
    ", ако използвате мобилен браузър и вече сте инсталирали приложението",
  "logActions.null": "Всички",
  "logActions.create": "добави",
  "logActions.update": "промени",
  "logActions.destroy": "изтри",
  "logActions.reconcile": "потвърди",
  "logActions.reconcile_cap": "Потвърди",
  "logActions.remove_active_sessions": "изтри активните сесии",
  "logActions.cancel_payments": "отмени на плащания",
  "logActions.perform_create_payment_return": "връщане на средства в баланс",
  "logActions.regenerate": "прегенерира",
  "logActions.init": "започна",
  "paymentTypes.null": "Всички",
  "paymentTypes.cash": "В брой",
  "paymentTypes.bank": "Банков превод",
  "permissions.controlPanelView": "Преглед на контолен панел",
  "permissions.billingAccountAdd": "Добавяне на каса",
  "permissions.billingAccountEdit": "Редакция на каса",
  "permissions.billingAccountDelete": "Изтриване на каса",
  "permissions.billingAccountView": "Преглед на каса",
  "permissions.billingAccountViewAtDisposal":
    "Преглед на разполагаемост на каса",
  "permissions.billingAccountViewBalance": "Преглед на салдо на каса",
  "permissions.billingAttributeAdd": "Добавяне на атрибут за таксуване",
  "permissions.billingAttributeEdit": "Редакция на атрибут за таксуване",
  "permissions.billingModelAdd": "Добавяне на модел за таксуване",
  "permissions.billingModelEdit": "Редакция на модел за таксуване",
  "permissions.billingReportView": "Преглед на отчет",
  "permissions.billingReportEdit": "Промяна на отчет (заключване, отключване)",
  "permissions.billingSettlementDelete": "Изтриване на плащане",
  "permissions.billingTransactionCreate": "Добавяне на трансацкия",
  "permissions.billingTransactionDelete": "Изтриване на трансакция",
  "permissions.billingTransactionView": "Преглед на трансацкии",
  "permissions.billingTransactionEdit": "Промяна на трансацкия",
  "permissions.billingConfirmCollection": "Потвърждаване на плащане",
  "permissions.blockAdd": "Добавяне на обект",
  "permissions.blockEdit": "Редакция на обект",
  "permissions.boardEdit": "Редакция на виртуално табло",
  "permissions.boardEditPost": "Редакция на съобщения във виртуално табло",
  "permissions.chargeAdd": "Добавяне на задължение",
  "permissions.chargeDelete": "Изтриване на задължение",
  "permissions.collectionGlobalView": "Преглед на секция Събирания",
  "permissions.customerEdit": "Редакция на клиентски профил",
  "permissions.customerView": "Преглед на клиенти",
  "permissions.estateCustomerAdd": "Добавяне на клиент към имот",
  "permissions.estateCustomerDelete": "Премахване на клиент от имот",
  "permissions.estateEdit": "Редакция на имот",
  "permissions.estateGlobalView": "Преглед на всички управлявани имоти",
  "permissions.estateSettleAmount": "Добавяне на плащане към имот",
  "permissions.managementBulkImport": "Групова редакция",
  "permissions.noteEdit": "Редакция на бележка",
  "permissions.notesView": "Преглед на бележки",
  "permissions.teamAdd": "Добавяне на акаунт в екипа",
  "permissions.teamDelete": "Премахване на акаунт от екипа",
  "permissions.teamEdit": "Редакция на акаунтите в екипа",
  "permissions.teamView": "Преглед на акаунтите в екипа",
  "permissions.ticketEdit": "Редакция на сигнал",
  "permissions.ticketGlobalView": "Преглед на всички сигнали",
  "billing.unt": "ед.",
  "billing.change_of": "Промяна на",
  "billing.newattr": "Нов атрибут",
  "billing.value": "Стойност",
  "billing.attributes": "Атрибути",
  "billing.full_payment": "Пълна вноска",
  "billing.save": "Запазване",
  "billing.budget": "Бюджет",
  "billing.reports": "Отчети",
  "billing.payments": "Плащания",
  "billing.transactions": "Трансакции",
  "billing.obligations": "Задължения",
  "billing.tills": "Каси",
  "billing.payment_model": "Модел на таксуване",
  "billing.old_period": "стар период",
  "billing.balance_to": "Салдо към",
  "billing.total_per_floor": "Общо салдо на Етажна собственост",
  "billing.prepaid_fees": "Предплатени такси",
  "billing.obligations_to": "Задължения към",
  "billing.accrued_period": "Начислени през периода",
  "billing.collected_contr": "Събрани вноски",
  "billing.other_incomes": "Други приходи",
  "billing.total_income": "Общо приходи",
  "billing.total_expense": "Общо разходи",
  "billing.collection_remaining": "Общо оставащи за събиране",
  "billing.monthly_reports": "Месечни отчети",
  "billing.balances_per_month": "Салда по периоди",
  "billing.transaction_reversed_on": "Трансакцията е сторнирана на",
  "billing.change_Transaction": "Промяна на трансакция",
  "billing.from_type": "От тип",
  "billing.new_order": "Ново нареждане",
  "billing.withdraw": "Теглене",
  "billing.cash": "в брой",
  "billing.bank": "банка",
  "billing.easypay": "easypay",
  "billing.epay": "еpay",
  "billing.transaction_date": "Дата на трансакцията",
  "billing.expenses": "Разходи",
  "billing.bank_file_import": "Плащания чрез банково извлечение",
  "block.report_to": "Отчет към",
  "block.no_reports": "Няма налични отчети",
  "block.available": "На разположение",
  "block.current_liabilities": "Текущи задължения",
  "block.arrears": "Просрочени задължения",
  "block.clients": "Клиенти",
  "block.signals_info":
    "\nСигналите са средство за комуникация на проблеми и предложения,\nсвързани с етажната собственост, към която принаджлежите.\n",
  "block.signal": "Сигнализирай",
  "block.welcome": "Добре дошли!",
  "block.info_functionality":
    "\nИскате ли да разберете повече за функционалността на таблото?\n",
  "block.enter": "Въведи ме",
  "block.object_archived": "Този обект е архивиран",
  "block.notes": "Бележки",
  "block.messages": "Съобщения",
  "block.info": "Информация",
  "block.documents": "Документи",
  "block.location_balance": "Салдо за обекти",
  "block.current_balances": "Текущи салда",
  "block.reference_monthly_rep":
    "\nСправката за период е базирана на публикуваните месечни отчети. С\nтази отметка можете да изведете само текущите салда.\n",
  "block.download": "Изтегли",
  "block.reference_per_loc": "Справка за салда по обекти",
  "block.new_building": "Нов блок",
  "block.archive": "Архивирай",
  "block.to_archive": "Архивиране на",
  "block.archiving_disable_attr":
    "Архивирането на обекта ще доведе до деактивиране на всички автоматични процеси, \nвключително он-лайн плащания. Сигурни ли сте, че иската да продължите?",
  "block.address": "Адрес",
  "block.auto_generate_rep": "Автоматично генериране на месечни задължения",
  "block.configured_budget_info":
    "\nКонфигурираният бюджет се използва за автоматично създаване на\nмесечни задължения. Задълженията се генерират последния ден от\nмесеца и са достъпни за онлайн плащане от 1-ви следващия месец.\n",
  "block.auto_notifications_debts":
    "Автоматични нотификации за текущи задължения",
  "block.auto_email_notifs":
    "\nАвтоматично изпращане на е-мейл нотификации с текущите задължения\nна всеки клиент. Изпращат се първата сряда от месеца.\n",
  "block.virtual_dash": "Виртуално табло",
  "block.allows_access_email":
    "\nПозволява достъп до системана на всички обитатели с конфигуриран\nе-мейл адрес.\n",
  "block.epay_easypay_integr": "Epay / EasyPay интеграция",
  "block.current_payments_info":
    "\nТекущите задължения стават достъпни за плащане през системата на\nePay и EasyPay. Събраните суми се превеждат по избрана от вас\nсметка. Начислените от доставчика трансакционни такси се добавят\nкъм фиксирания месечен абонамент.\n",
  "block.card_payments": "Картови плащания",
  "block.allows_card_payment":
    "\nПозволява на собственици и обитатели да заплатят текущите си\nзадължения с карта директно от виртуалното табло на Умен Вход. При\nкартови плащания трансакционната такса е включена и се заплаща от\nклиента.\n",
  "block.none_available": "Няма налични обекти",
  "block.create_new": "Създай нов",
  "block.active": "Активни",
  "block.archive": "Архив",
  "block.new_object": "Нов обект",
  "block.number_of_properties": "Брой имоти",
  "board.no_new_msg": "Няма нови съобщения",
  "board.new_message": "Ново съобщение",
  "board.change_message": "Промени съобщение",
  "board.upload_new_file": "Качи нов файл",
  "board.attached_documents": "Прикачени документи",
  "board.uploading": "Качва се..",
  "board.notif_to_inhabitant": "Известие към обитателите",
  "board.notifs_mail_viber":
    "\nОбитателите свързани с този обект получават известие по мейл и Viber\nпри всяко ново съобщение. Премахнете отметката за да изключите тази\nфункционалност. Известията се изпращат след около 5 минути, което\nпозволява да направите корекции или да премахнете съобщението.\n",
  "common.back": "Назад",
  "common.widescreen": "Широк екран",
  "common.confirm_deletion": "Потвърди Изтриване",
  "common.want_to_delete": "Желате ли да изтриете",
  "common.forever": "завинаги",
  "common.this_op_irreversible": "Тази операция е необратима",
  "common.page": "Страница",
  "common.page_of": "oт",
  "estate.add_inhabitat": "Добавяне на обитател",
  "estate.inhabitat": "Обитател",
  "estate.choose_profile": "Изберете профил",
  "estate.profile": "Профил",
  "estate.select_type": "Изберете тип",
  "estate.tenant": "Наемател",
  "estate.owner": "Собственик",
  "estate.client_number": "Кл. Номер:",
  "estate.floor": "Етаж",
  "estate.pay": "Плати",
  "estate.prepay": "Предплати",
  "estate.return_deposit": "Върни депозит",
  "estate.add_obligation": "Добави задължение",
  "estate.auto_bill_off": "Автоматичното таксуване на този имот е изключено",
  "estate.no_periodic_bill": "Няма да се начисляват нови периодични задължения",
  "estate.unset_debt": "непогасена сума",
  "estate.count_debt": "брой задължения",
  "estate.ap1": "Ап. 1",
  "estate.has_debt": "Само с непогасени задължения",
  "estate.unsettled_amount": "Непогасена сума",
  "estate.from": "От",
  "estate.to": "До",
  "estate.add_estate": "Добавяне на имот",
  "estate.change_estate": "Промяна на имот",
  "estate.preferred_payment_method": "Предпочитан начин на плащане",
  "estate.pick_payment_method": "Изберете начин на плащане",
  "estate.automatic_tax": "Автоматично таксуване",
  "estate.prefer": "Предпочита",
  "estate.inst_to": "Вноска към Ет.с.",
  "estate.sorting": "Сортиране",
  "estate.desc_liabiliies": "Задължения (низходящо)",
  "estate.asc_liabiliies": "Задължения (възходящо)",
  "estate.properties": "имоти",
  "estate.new_property": "Нов имот",
  "estate.property_search": "Търсене на имот",
  "estate.branch_numb": "Кл.Н",
  "estate.liab_own_estate": "Задължения към моите имоти",
  "estate.proceed_forward_stripe":
    'След като натиснете бутона "Продължи" ще бъдете прехвърлени към сайта на\nStripe за да заплатите',
  "estate.payment_to": "Плащане на задължения към",
  "estate.new_payment_to": "Ново плащане към",
  "estate.automatic_payment": "Автоматично погасяване на текущите задължения",
  "estate.checkmark_debt_order":
    "\nПо подразбиране, текущите задължения се погасяват от\nнай-старо към най-ново. Чрез тази отметка можете да\nизключите тази функционалност и да изберете точно кои\nзадължения да заплатите по-долу.\n",
  "estate.deposit_return": "Връщане на депозит",
  "estate.count_unpaid_debt": "Брой непогасени задължения",
  "estate.payment_details": "Детайли за плащането",
  "estate.transaction_fee": "Административна такса",
  "estate.prepay_1_months": "1 месец",
  "estate.prepay_3_months": "3 месеца",
  "estate.prepay_6_months": "6 месеца",
  "estate.prepay_12_months": "12 месеца",
  "estate.choose_prepay_period": "Изберете период за предплащане",
  "estate.current_debt": "Текущи задължения",
  "estate.typical_monthly_payment": "Настояща месечна вноска",
  "estate.enable_prepay": "Искам да предплатя за няколко месеца",
  "estate.prepay_amount": "Предплатена сума",
  "estate.total_amount": "Обща сума",
  "file.common": "Общи",
  "file.no_attached_documents": "Няма прикачени документи",
  "file.change_directory": "Промяна на директория",
  "file.document_select": "Избор на документи",
  "file.directory": "Директория",
  "file.folder": "Папка",
  "file.attach": "Прикачи",
  "management.see": "Виж",
  "management.download": "Свали",
  "management.usage": "Потребление",
  "management.payment_method": "Нaчин на плащане",
  "management.from_date": "От Дата",
  "management.details": "Детайли",
  "management.archived": "архивиран",
  "management.select_object": "Изберете обект",
  "management.customer_number": "Кл. Номер",
  "management.property": "имота",
  "management.amount_to_distribute": "Сума за разпределяне",
  "management.per_basis": "На база",
  "management.email": "E-Mail",
  "management.mobile_phone": "Мобилен телефон",
  "management.contacts": "Контакти",
  "management.income_expenses": "Приходи и разходи",
  "management.intitial_entrys": "Първоначален брой на редове за въвеждане",
  "management.select_template": "Изберете шаблон за въвеждане",
  "management.table_copied_to_memory": "Таблицата е копирана в паметта!",
  "management.copy": "Копирай",
  "management.data_saved_successfuly": "Данните са запазени успешно!",
  "management.error_saving":
    "Възникна грешка при запазването. Моля проверете входящите и опитайте отново",
  "management.add_row_above": "Добави ред отгоре",
  "management.add_row_below": "Добави ред отдолу",
  "management.delete_row": "Изтрий ред",
  "management.no_payments": "Няма постъпили плащания",
  "management.unconfirmed_payments": "Непотвърдени плащания",
  "management.summary": "Обобщение",
  "management.per_month": "По месец",
  "management.per_object": "По обект",
  "management.per_day": "По ден",
  "management.history": "История",
  "management.collected_amount": "Събрана сума от",
  "management.confirm_collected":
    "Сигурни ли сте че искате да потвърдите събраните",
  "management.collected_for_period": "Събрани за периода",
  "management.unconfirmed": "Непотвърдени",
  "management.confirmed": "Потвърдил",
  "management.delete_role": "Изтриване на роля",
  "management.confirm_role_deletion":
    "Сигурни ли сте, че искате да изтриете ролята",
  "management.new_role": "Нова роля",
  "management.changes_saved_successfully": "Промените са запазени успешно",
  "management.role_name": "Име на ролята",
  "management.more": "още",
  "management.activity_report": "Отчет на действията",
  "management.user_change": "Промяна на потребител",
  "management.new_user": "Нов потребител",
  "management.last_name": "Фамилия",
  "management.select_role": "Изберете роля",
  "management.to": "на",
  "management.with_name": "с име",
  "management.with_title": "със заглавие",
  "management.with_first_name": "с първо име",
  "management.with": "с",
  "management.for_amount_of": "за сумата от",
  "management.logout_all_devices":
    "Излизане от всички устройства използващи този профил",
  "management.confirm_logout_devices":
    "Сигурни ли сте, че искате да излезете от всички устройства използващи този профил?",
  "management.deactivate_user": "Деактивиране на потребителя",
  "management.confirm_user_deactivation":
    "Сигурни ли сте, че искате да деактивирате потребителя",
  "management.deactivate": "Деактивирай",
  "management.search_email_name_role": "Търси по e-mail, име и роля.",
  "management.roles": "Роли",
  "management.team": "Екип",
  "management.action_history": "История на действията",
  "management.subscription_settings": "Абонамент и настройки",
  "management.transactions": "Трансакции",
  "note.new_note": "Нова бележка",
  "note.content": "Съдържание...",
  "note.error": "Грешка",
  "note.add": "Добави",
  "note.cashier_printout": "В разпечатка за касиер",
  "note.cashier_note": "Бележка за касиер",
  "profile.change_profile": "Промяна на профил",
  "profile.new_profile": "Нов профил",
  "profile.board_member": "Член на УС",
  "profile.company": "Фирма",
  "profile.unique_ID": "ЕИК",
  "profile.manager": "Управител",
  "profile.manager_placeholder": "Иван Иванов",
  "profile.residents": "обитатели",
  "profile.copy_selected_emails": "Копирай e-mail адрес на избраните",
  "profile.management_board": "УС",
  "ticket.created_in_last": "Създаден през последните",
  "ticket.in_condition": "В състояние",
  "ticket.no_comments": "Все още няма коментари за този сигнал.",
  "ticket.delete_comment": "Изтриване на коментар",
  "ticket.confirm_deletion":
    "Сигурни ли сте, че искате да изтриете този коментар",
  "ticket.comment_deleted": "Коментарът е изтрит",
  "ticket.edit_comment": "Редактиране на коментар",
  "ticket.change_signal": "Промяна на сигнал",
  "ticket.send_signal": "Изпрати сигнал",
  "ticket.issue_type": "Тип проблем",
  "ticket.select_user": "Изберете потребител",
  "ticket.official": "Служебен",
  "ticket.new": "Нови",
  "ticket.assigned_to_me": "Назначени на мен",
  "ticket.in_progress": "В процес на работа",
  "ticket.completed": "Завършени",
  "ticket.filtered": "Филтрирани",
  "ticket.upload": "Качи",
  "helpers.error_check_data":
    "моля проверете подадените данни и опитайте отново",
  "helpers.try_again": "моля опитайте отново.",
  "charge.delete_selected": "Изтрии избраните задължения",
  "charge.delete_due": "Изтриване на задължения",
  "charge.selected_for_deletion": "Избрали сте да изтриете следните задължения",
  "charge.generate_for_period":
    "С това действие ще генерирате отново автоматично начислените \nзадължения за периода",
  "charge.needed_if_change":
    "Това е необходимо при значителни промени по бюджета или атрибутите \nна група имоти (напр. обитаемост) след като вече са били \nгенерирани.",
  "charge.payment_restored":
    "Всички направени плащания ще бъдат върнати в баланса на \nсъответните имоти и ще се използват за автоматично погасяване \nнакрая на деня.",
  "charge.regenerate": "Прегенерирай",
  "charge.recalc_auto_debt":
    "Преизчисляване на автоматично начислени задължения",
  "charge.for_period": "По период",
  "charge.period": "Период",
  "charge.automatically_billed": "Автоматично начислени задължения",
  "charge.unit": "бр",
  "charge.all_debt": "Всички задължения",
  "charge.to_property": "По имот",
  "charge.current": "текущи",
  "charge.overdue": "просрочени",
  "charge.total": "Общо",
  "charge.search": "търсене",
  "charge.search_capital": "Търсене",
  "charge.branch_numb": "кл.н.",
  "charge.condition": "състояние",
  "charge.from_date": "от дата",
  "charge.to_date": "до дата",
  "charge.model": "модел",
  "charge.billing_model": "Модел на таксуване",
  "charge.obligations": "задължения",
  "charge.no_matter": "Без значение",
  "charge.return_obligation": "Връщане на задължение",
  "charge.balance": "Баланс",
  "charge.cause": "Причина",
  "charge.return_to_balance": "Върни в баланса",
  "charge.fund": "Каса",
  "charge.current_capital": "Текущи",
  "charge.overdue_capital": "Просрочени",
  "charge.regen_auto_debt": "Прегенериране на автоматични задължения за период",
  "component.charge-table.description_header":
    "Задължение към ${params.fund} от ${params.date}",
  "component.pay-by-card.transaction_fee_info": `
      Моля обърнете внимание на допълнителна административна такса от 2% (но не по-малко от 2 лв.),
      която се прилага към сумата на всяко плащане. Тази такса е въведена с цел да покрие разходите,
      свързани с обработката на трансакцията.
    `,
  "fund.public_in_reports": "Публичен (присъства в отчетите)",
  "fund.balance": "Салдо",
  "fund.create_fund": "Създаване на нов фонд",
  "fund.change_of": "Промяна на",
  "model.change": "Промяна на модел",
  "model.new": "Нов модел",
  "model.attribute": "Атрибут",
  "model.ic": "ИЧ%",
  "model.to_checkout": "Към каса",
  "model.min_price": "Минимална цена",
  "model.price": "Цена",
  "model.foreach_perc": "5 лева за всеки % идеални части, минимум 4.20",
  "settlement.confirm_payment_to": "Потвърждаване на плащане към",
  "settlement.confirm_payment":
    "Сигурни ли сте че искате да потвърдите плащане от",
  "settlement.to": "към",
  "settlement.paid": "Платено",
  "settlement.payment_info": "Информация за плащането",
  "settlement.payment_number": "Плащане Номер",
  "settlement.payment_to": "Плащане към",
  "settlement.delete_payment": "Изтриване на плащане към",
  "settlement.delete_confirmation":
    "Сигурни ли сте че искате да изтриете плащане от",
  "settlement.payment_method": "Начин на плащане",
  "settlement.deleted": "Изтрити",
  "settlement.client_number": "Клиентски Номер",
  "settlement.show_deleted": "Показване на изтрити",
  "settlement.payments": "плащания",
  "settlement.casheer": "Касиер",
  "settlement.fees": "Такси",
  "settlement.payment_reversed": "Плащането е сторнирано на",
  "settlement.reverse_transaction": "Сторниране на трансакция",
  "settlement.reason_for_reverse": "Причина за сторниране на трансакцията",
  "settlement.page.title": "Плащания",
  "sandbox.demo_version": "Демо версия",
  "sandbox.welcome": "Добре дошли в демо версията на Умен Вход!",
  "sandbox.intro":
    "Тук можете свободно да изпробвате много от функционалностите,\nкоито предлагаме.",
  "sandbox.demo_obj":
    "За ваше улеснение сме създали обект с набор от имоти, каси и\nплащания, които можете да променяте както пожелаете.",
  "sandbox.feedback":
    "Имате въпроси или искате да разберете повече за предлаганите от\nнас услуги? Не се колебайте да изпратите запитване на",
  "authwrap.letter": "Вход с имейл",
  "authwrap.password": "Вход с парола",
  "authwrap.demo": "Демо среда",
  "authwrap.agreement":
    "С подаване на заявка за вписване, вие декларирате, че сте прочели,\nсъгласни сте и се задължавате да спазвате",
  "license.terms_of_use": "условията за ползване",
  "login.loginas": "Влез като потребителя",
  "login.admin_loginas":
    "Администраторите могат да влизат в системата като друг потребител.\nИскате ли да продължите като",
  "login.link_send":
    "На посоченият адрес ще бъде изпратено писмо с линк за достъп",
  "login.instruction_mail":
    "До няколко минути на посоченият адрес ще пристигне писмо с инструкции\nза вход в системата.",
  "login.nomail_info":
    'Ако това не се случи, опитайте да се впишете отново чрез "Достъп с \nписмо". За да използвате "Достъп с парола" е необходимо да създадете \nтакава.',
  "login.pass_manage": "Промяна или създаване парола",
  "login.understood": "Разбрах",
  "login.password": "Парола",
  "login.repeat_password": "Повтори парола",
  "login.tologin": "Към вход",
  "password.change": "Промяна на парола",
  "password.change2": "промяна на паролата",
  "password.successful_recovery": "Успешно възстановяване",
  "password.recovery_mail":
    "Към вашата електронна поща е изпратено писмо с линк за възствановяване \nна достъпа до потребителкия ви акаунт.",
  "register.tryout":
    "Изпробвайте възможностите на платформата без обвързващи условия",
  "register.existing":
    "Ако вашата сграда вече използва Умен Вход, можеш да се впишете",
  "register.demo": "Регистрация",
  "tour.management": "Детайли за управлението на имота.",
  "tour.charges": "Задължения към вашите имоти.",
  "tour.notes": `
        Бележки. Тук може да се попълва всякаква информация, която
        е само за служебно ползване и не се вижда от клиенти/обитатели/собственици.
      `,
  "tour.messages": `
        Тук можете даследите за детайли за насрочени дейности във
        вашата сграда - например кога наш касиер ще ви посети, за коя дата е
        насрочено Общо събрание, на коя дата е предвидено обезпаразитяване и др.
      `,
  "tour.info": `
        Тук поместваме обща информация за вашата сграда - адрес,
        брой и тип индивидуални обекти, услуги от КОС България, важни телефони
        на доставчици на услуги и др.
      `,
  "tour.docs": `
        Тук можете да се запознаете с важни документи, отнасящите до
        управлението и поддръжката на сградата ви - протоколи от Общи събрания,
        договори с доставчици и др.
      `,
  "info.obligation_paid":
    "Това задължение e платено и не може да бъде променено.",
  "info.obligation_generated1": "Това задължение е генерирано автоматично.",
  "info.obligation_generated2":
    "Всяка промяна ще премахне информацията за как е \n съставено.",
  "warning.importFullReplacement": `Внимание: данните в тази таблица изцяло
      заместват вече въведените стойности за даден обект.
      Премахването на редове се тълкува като "изтриване".
      `,
  "error.savng": `Грешка при запазването. Моля опитайт отново \n или се свържете с обслужване на клиенти`,
  "error.error_copying_table": "Възникна грешка при копиране на таблицата!",
  "error.error_occurred": "Възникна грешка",
  "error.error_try_later": "Възникна грешка. Моля опитайте по-късно.",
  "error.page_not_found": "Страницата не е намерена",
  "error.file_upload_failed": "Файлът не може да бъде записан",
  "stat.estates": "Брой имоти",
  "stat.estates_growth": "Ръст имоти (%YoY)",
  "stat.active_blocks": "Активни обекти",
  "stat.active_blocks_growth": "Ръст обекти (%YoY)",
  "stat.deposits_per_month": "Плащания по месеци",
  "stat.deposit_method_percent": "Плащания по начин на плащане",
  "stat.no_payments_data": "Няма постъпили плащания",
  "component.managementPage.financeOverviewHeader": "Финансов обзор",
  "component.managementPage.managementBulkImportHeader": "Групова редакция",
  "dashboard.open.signals": "Активни Сигнали",
  "dashboard.no.signals.header": "Няма активни сигнали",
  "dashboard.no.signals.paragraph": "В момента няма активни сигнали",
};

import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { Grid, StyledTopMenu } from "../../components/base/elements";
import { Menu } from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import { reverse, routes } from "../../routes";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { TeamPage } from "./team/page";
import { AppControlPanelPage } from "./appcontrolpanel/page";
import { Loading } from "../../components/common/loading";
import { t } from "../../constant/translations";
import { FinancePage } from "./finance/page";
import { connect } from "react-redux";
import { TransactionsPage } from "./transactions/page";
import { SettlementPage } from "./settlements/page";
const BulkEditPage = lazy(() => import("./bulk/page"));
const CashierCollectionPage = lazy(() => import("./cashier-collection/page"));

const StyledColumn = styled(Grid.Column)`
  //padding: 0 0 !important;
`;

export const ManagementPageComponent = (props: Props) => {
  return (
    <React.Fragment>
      <Suspense fallback={<Loading />}>
        <StyledTopMenu stackable={true}>
          <RoleRequired permission={permissions.financeGlobalView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.financeOverview)}
                >
                  {t("component.managementPage.financeOverviewHeader")}
                </Link>
              )}
            />
          </RoleRequired>
          <RoleRequired permission={permissions.managementBulkImport}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.bulkEdit)}
                >
                  {t("component.managementPage.managementBulkImportHeader")}
                </Link>
              )}
            />
          </RoleRequired>
          <RoleRequired permission={permissions.collectionGlobalView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.cashierCollections)}
                >
                  {t("settlement.casheer")}
                </Link>
              )}
            />
          </RoleRequired>
          <RoleRequired permission={permissions.collectionGlobalView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.settlements)}
                >
                  {t("settlement.page.title")}
                </Link>
              )}
            />
          </RoleRequired>

          <RoleRequired permission={permissions.financeGlobalView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.transactions)}
                >
                  {t("management.transactions")}
                </Link>
              )}
            />
          </RoleRequired>
          <RoleRequired permission={permissions.teamView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.team)}
                  exact={true}
                >
                  {t("management.team")}
                </Link>
              )}
            />
          </RoleRequired>
          <RoleRequired permission={permissions.controlPanelView}>
            <Menu.Item
              as={() => (
                <Link
                  activeClassName="active"
                  className="item"
                  to={reverse(routes.controlPanel)}
                  exact={true}
                >
                  {t("management.subscription_settings")}
                </Link>
              )}
            />
          </RoleRequired>
        </StyledTopMenu>
        <Switch>
          <Route
            exact={true}
            path={routes.manage}
            render={() => {
              let baseRedirect = routes.bulkEdit;
              if (props.user.hasPermission(permissions.financeGlobalView)) {
                baseRedirect = routes.financeOverview;
              }

              return <Redirect to={baseRedirect} />;
            }}
          />
          <Route exact={true} path={routes.bulkEdit} component={BulkEditPage} />
          <Route
            exact={true}
            path={routes.cashierCollections}
            component={CashierCollectionPage}
          />
          <Route
            exact={true}
            path={routes.financeOverview}
            component={FinancePage}
          />
          <Route
            exact={true}
            path={routes.transactions}
            component={TransactionsPage}
          />
          <Route path={routes.team} component={TeamPage} />
          <Route path={routes.controlPanel} component={AppControlPanelPage} />
          <Route
            exact={true}
            path={routes.payments}
            component={SettlementPage}
          />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export const ManagementPage = connect(mapStateToProps)(
  withRouter(ManagementPageComponent)
);

import { apirequest } from "../../helpers/api-client";
import { newApi } from "../restdux/index";

export const ticketManager = newApi("ticket", "/api/ticket/", true);
const TOGGLE_UPVOTE = "app/ticket/TOGGLE_UPVOTE";

export function reducer(state, action) {
  const newState = ticketManager.reducer(state, action);
  switch (action.type) {
    case TOGGLE_UPVOTE:
      return {
        ...newState,
        entities: newState.entities.map((ticket) =>
          ticket.id === action.data.id
            ? {
                ...ticket,
                upvotes_count: ticket.current_user_voted
                  ? ticket.upvotes_count - 1
                  : ticket.upvotes_count + 1,
                current_user_voted: !ticket.current_user_voted,
              }
            : ticket
        ),
      };
    default:
      return newState;
  }
}

export type Ticket = {
  id: number,
  block: number,
  title: string,
  description: string,
  ticket_type: string,
  status: string,
  author: Object,
  assignee: Object,
  internal: boolean,
  created_at: string,
  updated_at: string,
  deleted_at: ?string,
};

export function fetchTickets(params) {
  return ticketManager.actions.list(params);
}

export function getTicket(ticketId) {
  return ticketManager.actions.get({ id: ticketId });
}

export function createTicket(data) {
  return ticketManager.actions.create(data, { pushToFront: true });
}

export function updateTicket(data) {
  return ticketManager.actions.update(data);
}

export function deleteTicket(data) {
  return ticketManager.actions.del(data);
}

export function toggleUpvote(entityId) {
  return (dispatch) => {
    return apirequest("post", `/api/ticket/${entityId}/toggle_upvote/`).then(
      () => {
        dispatch({
          type: TOGGLE_UPVOTE,
          data: {
            id: entityId,
          },
        });
      }
    );
  };
}

export const clearError = ticketManager.actions.clearError;

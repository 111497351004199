import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { ErrorWrap } from "../../../../helpers/error";
import type AuditLogEntry from "../../../../redux/modules/audit-log";
import { fetchFullAuditLog } from "../../../../redux/modules/audit-log";
import { withRouter } from "react-router";
import { withSorting } from "../../../../helpers/sortby";
import { t } from "../../../../constant/translations";
import { getUrlParams } from "../../../../components/common/urlparams";
import dayjs from "dayjs";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/common/datatable";
import { DetailsModal } from "./details-modal";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../../../routes";

type AuditListProps = {
  auditLog: ?Array<AuditLogEntry>,
  error: Object,
  loading: boolean,
  fetchDeps: Function,
  openFilter: Function,
};

class AuditListComponent extends Component<AuditListProps> {
  componentDidMount() {
    this.loadDeps();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.loadDeps();
    }
  }

  loadDeps = () => {
    const { location } = this.props;
    const params = {
      ...getUrlParams(location),
    };
    this.props.fetchDeps(params);
  };

  render() {
    const { users, blocks, auditLog, error, loading, sortColumn } = this.props;
    if (error.exists()) {
      return <div>{error.getGlobal()}</div>;
    }
    if (!auditLog || !users) {
      return <Loader active />;
    }
    if (loading) {
      return <Loader active />;
    }
    return (
      <DataTable data={auditLog} sortable={true}>
        <DataTableColumn
          name={t("labels.user")}
          render={(logEntry) => (
            <Link
              to={reverse(routes.teamDetails, {
                userId: logEntry.user.id,
              })}
            >
              {logEntry.user.name}
            </Link>
          )}
        />
        <DataTableColumn
          name={t("labels.block")}
          render={(logEntry) => {
            if (
              logEntry.response.block &&
              typeof logEntry.response.block == "object"
            ) {
              return logEntry.response.block.name;
            }
            if (
              logEntry.response.block &&
              typeof logEntry.response.block == "number"
            ) {
              let block = blocks.find((e) => {
                return e.id === logEntry.response.block;
              });
              return block.name;
            }
          }}
        />
        <DataTableColumn
          name={t("labels.action")}
          render={(logEntry) =>
            t(`logActions.${logEntry.action}`, logEntry.action)
          }
        />

        <DataTableColumn
          name={t("labels.resource")}
          render={(logEntry) => {
            try {
              return t(`resources.${logEntry.resource}`, logEntry.resource);
            } catch (error) {
              return logEntry.resource;
            }
          }}
        />
        <DataTableColumn
          name={t("labels.date")}
          render={(logEntry) =>
            dayjs(logEntry.created_at).format("YYYY-MM-DD HH:mm")
          }
          {...sortColumn("created_at")}
        />
        <DataTableColumn
          name={t("management.details")}
          render={(logEntry) => <DetailsModal entry={logEntry} />}
        />
      </DataTable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditLog: state.auditLog.entities,
    users: state.user.entities,
    blocks: state.blocks.entities,
    error: ErrorWrap.fromError(state.auditLog.error || state.user.error),
    auditLoading: state.auditLog.pending || state.user.pending,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDeps: (params) => (dispatch) =>
        Promise.all([dispatch(fetchFullAuditLog(params))]),
    },
    dispatch
  );
}

const SortedListComponent = withRouter(withSorting(AuditListComponent));

export const AuditList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SortedListComponent);

import { reverse as namedReverse } from "named-urls";

export const reverse = namedReverse;
export const reverseWithSearchParams = (url, params) => {
  const [base, paramStr] = url.split(/\?(.*)/);
  const newUrl = reverse(base, params);
  if (paramStr) {
    return `${newUrl}?${paramStr}`;
  }
  return newUrl;
};

export const routes = {
  baseURL: "/ui/",
  baseAuth: "/ui/auth/",
  authLogin: "/ui/auth/login/",
  authLoginPass: "/ui/auth/login/cred/",
  authLoginRegister: "/ui/auth/login/register/",
  authPasswordReset: "/ui/auth/password-reset/",
  authPasswordResetSuccess: "/ui/auth/password-reset-success/",
  authSetPassword: "/ui/auth/set-password/:uid/:token/",
  authLoginSuccess: "/ui/auth/login-success",

  blockList: "/ui/blocks/",
  blockNew: "/ui/blocks/new",
  estateList: "/ui/estates/",

  tickets: "/ui/tickets",

  blockBase: "/ui/block/:blockId/",
  blockDetails: "/ui/block/:blockId/details",
  blockProfiles: "/ui/block/:blockId/profiles",
  blockEstateList: "/ui/block/:blockId/estates",
  blockBilling: "/ui/block/:blockId/billing",
  blockBudget: "/ui/block/:blockId/billing/budget",
  blockSettlements: "/ui/block/:blockId/billing/settlements",
  blockSettlementDetails: "/ui/block/:blockId/billing/settlements/:settleId",

  blockTransactions: "/ui/block/:blockId/billing/transactions",
  blockCharges: "/ui/block/:blockId/billing/charges",
  blockChargeList: "/ui/block/:blockId/billing/charges/list",
  blockReports: "/ui/block/:blockId/billing/reports",
  blockTickets: "/ui/block/:blockId/tickets",
  ticketDetails: "/ui/block/:blockId/ticket/:ticketId",

  estateDetails: "/ui/block/:blockId/estate/:estateId",
  estateSettlementDetails:
    "/ui/block/:blockId/estate/:estateId/settlement/:settleId",

  manage: "/ui/manage/",
  bulkEdit: "/ui/manage/bulk-edit/",
  cashierCollections: "/ui/manage/cashier-collections/",
  financeOverview: "/ui/manage/finance-overview/",
  transactions: "/ui/manage/transactions/",
  settlements: "/ui/manage/settlements/",
  settlementDetails: "/ui/manage/settlements/:settleId",
  team: "/ui/manage/users/",
  teamAudit: "/ui/manage/users/audit/",
  teamRoles: "/ui/manage/users/roles/",
  teamDetails: "/ui/manage/users/details/:userId/",

  controlPanel: "/ui/manage/control-panel/",
};

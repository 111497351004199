import React, { useState, useEffect } from "react";
import { Button, Divider, Form, Message, Modal } from "semantic-ui-react";
import { ConfirmModalAction } from "./confirm-modal";
import { StyledTriggerLink } from "./trigger-link";
import { t } from "../../constant/translations";

export type FormProps = {
  entity: Object,
  error: ?Object,
  initialData: ?Object,
  loading: Boolean,
  create: Function,
  update: Function,
  del: ?Function,
  clearError?: Function,
  manager?: Object,
  useTextTrigger?: boolean,
  trigger_props?: Object,
  deleteProps?: Object,
};

export const EditEntity = ({
  entity,
  error,
  loading,
  create,
  update,
  del,
  clearError,
  manager,
  header,
  children,
  useTextTrigger,
  trigger_props,
  deleteProps,
  customTrigger,
}: FormProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!loading && error && !error.exists()) {
      setModalOpen(false);
    }
  }, [loading, error]);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => {
    setModalOpen(false);
    if (clearError) clearError();
    if (manager) manager.clear();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (manager) {
      if (entity) {
        update(manager.formData);
      } else {
        create(manager.formData);
      }
    }
  };

  let trigger = (
    <Button
      size="tiny"
      onClick={handleOpenModal}
      basic={true}
      icon={entity ? "edit" : "plus"}
      {...(trigger_props || {})}
    />
  );

  if (customTrigger) {
    trigger = (
      <StyledTriggerLink href="#" onClick={handleOpenModal}>
        {customTrigger.text}
      </StyledTriggerLink>
    );
  }

  if (useTextTrigger) {
    trigger = (
      <StyledTriggerLink href="#" onClick={handleOpenModal}>
        {entity ? t("labels.change") : t("labels.create")}
      </StyledTriggerLink>
    );
  }

  return (
    <Modal
      open={modalOpen}
      onOpen={handleOpenModal}
      onClose={handleCloseModal}
      closeIcon="close"
      trigger={trigger}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form as="div">
          {modalOpen && children}
          <Divider horizontal />
          {error && error.exists() && (
            <Message error visible={true}>
              {error.getGlobal()}
            </Message>
          )}
          <Form.Group>
            {del && entity && (
              <ConfirmModalAction
                header={t("labels.deletion")}
                content={t("labels.confirm_continue")}
                trigger_props={{
                  negative: true,
                  fluid: true,
                  size: "medium",
                  content:
                    deleteProps && deleteProps.triggerText
                      ? deleteProps.triggerText
                      : t("labels.delete"),
                  basic: false,
                }}
                {...deleteProps}
                loading={loading}
                error={error}
                action={() => del(entity)}
              />
            )}
            <Button
              type="submit"
              positive
              fluid
              size="medium"
              loading={loading}
              onClick={handleSubmit}
            >
              {t("labels.save")}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

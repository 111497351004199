import React, { Component } from "react";
import { Button, Message, Modal, Popup } from "semantic-ui-react";
import { ErrorWrap } from "../../helpers/error";
import { StyledTriggerLink } from "./trigger-link";
import { t } from "../../constant/translations";

type ModalProps = {
  action: ?() => any,
  header: ?string,
  content: string,
  loading: boolean,
  disabled?: boolean,
  error?: ErrorWrap,
  trigger_props: Object,
  confirmLabel?: string,
  useTextTrigger?: boolean,
  textTriggerText?: string,
  triggerTooltipText?: string,
  confirmDisabled?: boolean,
};

type ModalState = {
  isOpen: boolean,
};

export class ConfirmModalAction extends Component<ModalProps, ModalState> {
  state = { isOpen: false, error: new ErrorWrap(null) };

  componentDidUpdate(oldProps) {
    if (oldProps.loading && this.props.error) {
      if (this.props.error.exists()) {
        this.setState({ error: this.props.error });
      } else {
        this.setState({ isOpen: false });
      }
    }
  }

  handleClose = () => this.setState({ isOpen: false });

  render() {
    const {
      header,
      trigger_props,
      action,
      content,
      confirmLabel,
      useTextTrigger,
      textTriggerText,
      loading,
      triggerTooltipText,
      confirmDisabled,
    } = this.props;

    return (
      <Modal
        open={this.state.isOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={!loading}
        trigger={
          useTextTrigger ? (
            <Popup
              disabled={!triggerTooltipText}
              content={triggerTooltipText}
              trigger={
                <StyledTriggerLink
                  {...trigger_props}
                  onClick={() =>
                    this.setState({ isOpen: true, error: new ErrorWrap(null) })
                  }
                >
                  {textTriggerText || t("logActions.reconcile_cap")}
                </StyledTriggerLink>
              }
            />
          ) : (
            <Popup
              disabled={!triggerTooltipText}
              content={triggerTooltipText}
              trigger={
                <Button
                  basic={true}
                  {...trigger_props}
                  onClick={() =>
                    this.setState({ isOpen: true, error: new ErrorWrap(null) })
                  }
                />
              }
            />
          )
        }
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        <Modal.Content>
          <p>{content}</p>
          {this.state.error.exists() && (
            <Message error content={this.state.error.getGlobal()} />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="remove"
            onClick={this.handleClose}
            content={t("common.back")}
            disabled={loading}
          />
          <Button
            icon="checkmark"
            onClick={action}
            content={confirmLabel || t("labels.yes")}
            loading={loading}
            disabled={confirmDisabled}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

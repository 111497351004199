// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { Grid, Loader, Message } from "semantic-ui-react";
import { withMedia } from "react-media-query-hoc";
import {
  fetchSettlements,
  settlementManager,
} from "../../../redux/modules/settlement";
import { getUrlParams } from "../../../components/common/urlparams";
import { ErrorWrap } from "../../../helpers/error";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SettlementTable } from "../../billing/settlement/settlement-table";
import { PageNavigationSimpleFromMeta } from "../../../components/common/page-navigation";
import {
  ContentFilterFormBody,
  settlementQueryFilterParams,
} from "../../billing/settlement/list-content-filter";
import {
  ContentFilterPanel,
  ContentFilterTrigger,
} from "../../../components/common/content-filter";
import { DownloadLink } from "../../../helpers/download-link";
import qs from "qs";
import dayjs from "dayjs";
import { Route } from "react-router-dom";
import { reverseWithSearchParams, routes } from "../../../routes";
import { SettlementModal } from "../../estate/settlement-modal";
import { Switch } from "react-router";
import { t } from "../../../constant/translations";

type Props = {
  location: Object,
  defaultFilters: ?Object,
  settlements: ?any,
  loading: ?boolean,
  error: ?ErrorWrap,
  clearError: ?Function,
  meta: ?Object,
  media: ?Object,
};

const StyledContentFilterPanel = styled(ContentFilterPanel)`
  min-height: 550px;
`;

const StyledPageNavigationSimpleFromMeta = styled(PageNavigationSimpleFromMeta)(
  {
    "margin-top": "0!important",
    "margin-bottom": "0!important",
  }
);

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export class SettlementListComponent extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false,
    };
    this.defaultFilters = {
      page_size: 25,
    };
  }

  componentDidMount() {
    this.getSettlements(this.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.getSettlements(this.props);
    }
  }

  getSettlements(props) {
    let argFilters = this.getFilters(props);
    props.fetchSettlements(argFilters);
  }

  getDownloadRequestParams = (props) => {
    let argFilters = this.getFilters(props);
    argFilters["format"] = "csv";
    return qs.stringify(argFilters, {});
  };

  getFilters = (props) => {
    let argFilters = getUrlParams(props.location);
    argFilters = { ...argFilters, ...this.defaultFilters };

    return argFilters;
  };

  handleSetFilterOpen = (filterOpen) => this.setState({ filterOpen });

  render() {
    const { settlements, loading, error, clearError, meta, media } = this.props;
    return (
      <Grid padded columns="equal">
        <Grid.Row>
          <Grid.Column>
            <StyledHeader>
              <Switch>
                <Route
                  path={routes.settlementDetails}
                  render={(props) => {
                    const returnURL = reverseWithSearchParams(
                      routes.settlements + this.props.location.search,
                      {
                        settleId: props.match.params.settleId,
                      }
                    );
                    return <SettlementModal returnURL={returnURL} {...props} />;
                  }}
                />
              </Switch>
              <StyledPageNavigationSimpleFromMeta meta={meta} />
              <div>
                <DownloadLink
                  size="tiny"
                  style={{ float: "right" }}
                  href={`/api/settlement?${this.getDownloadRequestParams(
                    this.props
                  )}`}
                  basic={true}
                  target={"_blank"}
                  download={
                    t("settlement.payments") +
                    `-${dayjs().format("YYYY-MM-DD-HHMM")}.csv`
                  }
                  icon={"download"}
                />
                <ContentFilterTrigger
                  style={{ float: "right" }}
                  onClick={() =>
                    this.setState({ filterOpen: !this.state.filterOpen })
                  }
                  queryParamConfig={settlementQueryFilterParams}
                  media={media}
                />
              </div>
            </StyledHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: "0 0" }}>
          <Grid.Column>
            <div>
              {error.exists() && (
                <Message
                  error
                  content={error.getGlobal()}
                  onDismiss={() => clearError()}
                />
              )}
            </div>
            <StyledContentFilterPanel
              open={this.state.filterOpen}
              onApply={() => this.handleSetFilterOpen(false)}
              queryParamConfig={settlementQueryFilterParams}
              FormBody={ContentFilterFormBody}
            >
              <div>
                {loading ? (
                  <Loader active={true} />
                ) : (
                  <SettlementTable
                    settlements={settlements}
                    estateLink={true}
                    detailsRoute={
                      routes.settlementDetails + this.props.location.search
                    }
                  />
                )}
              </div>
            </StyledContentFilterPanel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settlements: state.settlement.entities,
    loading: state.settlement.pending,
    error: ErrorWrap.fromError(state.settlement.error),
    meta: state.settlement.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSettlements,
      clearError: settlementManager.actions.clearError,
    },
    dispatch
  );
};

export const SettlementPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withMedia(SettlementListComponent));

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledHeader } from "../../../components/base/elements";
import { Dropdown, Form, Grid, Loader, Segment } from "semantic-ui-react";
import { apirequest } from "../../../helpers/api-client";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/common/datatable";
import { amountToBGN } from "../../../helpers/currency";
import { formatDate } from "../../../helpers/date";
import { t } from "../../../constant/translations";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "../../../redux/modules/app-settings";
import { FormdataManager } from "../../../components/common/formdata";
import { bindActionCreators } from "redux";
import { FormListInput } from "../../../components/common/form-list-input";
import toast from "react-hot-toast";

const StyledSegment = styled(Segment)({
  margin: "0 0 10px 0 !important;",
});

const InvoiceComponent = () => {
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const effect = async () => {
      const result = await apirequest("get", `/api/app-billing-invoice/`, {});
      setInvoices(result.body);
      setLoading(false);
    };
    effect();
  }, []);

  if (loading) {
    return (
      <StyledSegment>
        <Loader />
      </StyledSegment>
    );
  }

  return (
    <StyledSegment>
      <DataTable unstackable={true} data={invoices}>
        <DataTableColumn
          align="left"
          name={t("labels.date")}
          render={(el) => {
            return <span>{formatDate(el.created_at)}</span>;
          }}
        />
        <DataTableColumn
          align="right"
          name={t("labels.amount")}
          render={(el) => {
            return <span>{amountToBGN(el.amount)}</span>;
          }}
        />

        {/* For now, we commend status out - this table is for informational purposes only*/}
        {/*<DataTableColumn*/}
        {/*  align="right"*/}
        {/*  name="Състояние"*/}
        {/*  render={(el) => {*/}
        {/*    return <span>{t(`invoiceStates.${el.status}`)}</span>;*/}
        {/*  }}*/}
        {/*/>*/}

        <DataTableColumn
          align="right"
          name=""
          render={(el) => {
            return (
              <a href={el.pdf} target={"_blank"} rel="noopener">
                {t("management.download")}
              </a>
            );
          }}
        />
      </DataTable>
    </StyledSegment>
  );
};

function AppSettingsComponent({ pending, error, data, updateSettings }) {
  const formManager = FormdataManager.useState(data);
  console.log(formManager.formData, data);
  const updateSettingsHandler = () => {
    updateSettings(formManager.formData);
  };

  if (pending && !data) {
    return (
      <StyledSegment>
        <Loader />
      </StyledSegment>
    );
  }

  return (
    <StyledSegment>
      <Form onSubmit={updateSettingsHandler}>
        <Form.Field>
          <label>{t("labels.transaction_categories")}</label>
          <Dropdown
            additionLabel={`${t("labels.add")} `}
            search
            selection
            fluid
            multiple
            allowAdditions
            icon={null}
            noResultsMessage={t("placeholders.begin_writing_to_add")}
            {...formManager.getAttributes("transaction_categories")}
            options={formManager
              .getValue("transaction_categories", [])
              .map((entry) => {
                return { key: entry, value: entry, text: entry };
              })}
          />
          <p>{t("labels.transaction_categories_description")}</p>
        </Form.Field>
        <Form.Field>
          <label>{t("labels.enable_ticket_voting")}</label>
          <Form.Checkbox
            toggle
            checked={formManager.getValue("enable_ticket_voting")}
            onChange={() =>
              formManager.setValue(
                "enable_ticket_voting",
                !formManager.getValue("enable_ticket_voting")
              )
            }
          />
          <p>{t("labels.enable_ticket_voting_description")}</p>
        </Form.Field>
        <Form.Button
          type={"submit"}
          content={t("labels.submit")}
          loading={pending}
        />
      </Form>
    </StyledSegment>
  );
}

const AppSettings = connect(
  (state) => {
    return state.appSettings;
  },
  (dispatch) => {
    return bindActionCreators({ updateSettings }, dispatch);
  }
)(AppSettingsComponent);

export const AppControlPanelPageComponent = () => {
  return (
    <Grid padded fluid={true}>
      <Grid.Row>
        <Grid.Column>
          <StyledHeader as={"h3"}>{t("management.usage")}</StyledHeader>
          <InvoiceComponent />
          <StyledHeader as={"h3"}>Настройки</StyledHeader>
          <AppSettings />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const AppControlPanelPage = AppControlPanelPageComponent;

import { connect } from "react-redux";

const GlobalSettingsCheckComponent = ({ settings, check, children }) => {
  if (!settings || !check(settings)) {
    return null;
  }
  return children;
};

export const GlobalSettingsCheck = connect((state) => ({
  settings: state.appSettings.data,
}))(GlobalSettingsCheckComponent);

// @flow
import styled from "styled-components";
import React from "react";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { EstateEditModal } from "./estate-edit-modal";
import { translations } from "../../constant/translations";
import { Link } from "react-router-dom";
import { reverse, routes } from "../../routes";
import { CustomerListPanel } from "./customer-list-panel";
import { amountToBGN } from "../../helpers/currency";
import PayAction from "./pay";
import { device } from "../../config";
import { NotesModal } from "../note/notes-modal";
import { t } from "../../constant/translations";

const TextSmall = styled.div`
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${(props) => props.theme.textGray};
  text-transform: lowercase;
`;

const StyledAmount = styled.div`
  width: 70px;
  text-align: right;
  margin-left: 0.2rem;
  @media ${device.handheld} {
    margin-left: 1rem;
    font-size: 1.1rem;
  }
`;

const StyledAmountBalance = styled(StyledAmount)`
  width: 50px;
  @media ${device.handheld} {
    width: 60px;
  }
`;

const StyledEstateLink = styled(Link)`
  font-size: 1rem;
  line-height: 1.3rem;
  display: block;
  font-weight: bold;
  @media ${device.handheld} {
    font-size: 1.2rem;
  }
`;

const StyledCustomerList = styled.div`
  grid-area: customers;
  font-size: 0.8rem;
  line-height: 1.2rem;
  vertical-align: top;

  > a {
    display: block;
    font-size: 0.9rem;
  }
`;

const EstateList = styled.div`
  background-color: white;
  border-radius: 0.2rem;
  border: 1px solid ${(props) => props.theme.borderGrey};

  > div {
    border-top: 1px solid ${(props) => props.theme.borderGrey};

    :first-child {
      border-top: 0;
    }
  }
`;

const EstateListItem = styled.div`
  display: grid;
  grid-template-rows: auto;
  padding: 0.8rem;
  grid-template-columns: 120px 1fr 1fr auto;
  grid-row-gap: 1.3rem;
  grid-template-areas:
    "description billing billing billing"
    "customers customers actions actions";

  :hover {
    background-color: ${(props) => props.theme.rowHover};
  }

  @media ${device.handheld} {
    grid-template-columns: 140px auto 1fr auto;
    grid-template-areas: "description customers billing actions";
  }

  @media ${device.desktop} {
    grid-template-columns: 200px auto 1fr auto;
    grid-template-areas: "description customers billing actions";
  }

  @media ${device.largeDesktop} {
    grid-template-columns: 260px auto 1fr auto;
    grid-template-areas: "description customers billing actions";
  }
`;

const BlockSpan = styled.div`
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #666666;
  text-transform: lowercase;
`;

const EstateDescription = styled.div`
  grid-area: description;
  @media ${device.handheld} {
    margin-right: 2rem;
  }
`;

const EstateBillingDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-area: billing;
  place-self: start end;
  text-align: right;
`;

const Controls = styled.div`
  grid-area: actions;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  place-self: end end;

  width: 160px;

  &:empty {
    width: 0;
  }

  > div {
    margin-left: 0.4em;
  }

  @media ${device.handheld} {
    place-self: start end;
  }
`;

const ButtonWrap = styled.div`
  > button {
    margin-right: 0 !important;
  }
`;

export class EstateTableComponent extends React.Component {
  render() {
    const { estates, blockId } = this.props;
    if (!estates) {
      return null;
    }

    return (
      <EstateList>
        {estates.map((estate, idx) => {
          return (
            <EstateListItem key={idx}>
              <EstateDescription>
                <TextSmall>
                  {t(`estateTypes.${estate.etype}`, estate.etype).toLowerCase()}
                </TextSmall>
                <StyledEstateLink
                  to={reverse(routes.estateDetails, {
                    blockId: estate.block.id,
                    estateId: estate.id,
                  })}
                >
                  {!blockId && `${estate.block.name} - `} {estate.name}
                </StyledEstateLink>
              </EstateDescription>
              <RoleRequired staff={true} permission={permissions.customerView}>
                <StyledCustomerList>
                  <TextSmall>{t("labels.customers")}</TextSmall>
                  <CustomerListPanel estate={estate} />
                </StyledCustomerList>
              </RoleRequired>
              <EstateBillingDetails>
                <StyledAmount>
                  <TextSmall>{t("estate.prefer")}</TextSmall>
                  {estate.preferred_payment_method
                    ? t(
                        `paymentMethods.${estate.preferred_payment_method}`,
                        estate.preferred_payment_method
                      ).toLowerCase()
                    : t(`paymentMethods.cash`)}
                </StyledAmount>
                <StyledAmountBalance>
                  <TextSmall>{t("charge.balance")}</TextSmall>
                  {amountToBGN(estate.balance)}
                </StyledAmountBalance>
                <StyledAmount>
                  <TextSmall>{t("billing.obligations")}</TextSmall>
                  {amountToBGN(estate.debt)}
                </StyledAmount>
              </EstateBillingDetails>
              <Controls>
                <RoleRequired staff={true} permission={permissions.notesView}>
                  <div>
                    <NotesModal entity={estate} />
                  </div>
                </RoleRequired>
                <RoleRequired
                  staff={true}
                  permission={permissions.estateSettleAmount}
                >
                  <PayAction estate={estate} />
                </RoleRequired>
                <RoleRequired staff={true} permission={permissions.estateEdit}>
                  <ButtonWrap>
                    <EstateEditModal entity={estate} />
                  </ButtonWrap>
                </RoleRequired>
              </Controls>
            </EstateListItem>
          );
        })}
      </EstateList>
    );
  }
}

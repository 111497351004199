import React, { useEffect, useState } from "react";
import { Segment, Message, Divider, List } from "semantic-ui-react";
import { connect } from "react-redux";
import { fetchTickets } from "../../../redux/modules/ticket";
import { bindActionCreators } from "redux";
import { DashboardTicketEntity } from "../../ticket/ticket-dashboard-item";
import { usePagination } from "../../../components/common/pagination";
import { t } from "../../../constant/translations";
import { apirequest } from "../../../helpers/api-client";

const ActiveSignalsList = ({
  user,
  block,
  fetchDependencies,
  tickets = [],
}) => {
  const itemsPerPage = 5;

  useEffect(() => {
    fetchDependencies({
      block: block.id,
      status: "open",
    });
  }, [block.id, fetchDependencies]);

  const [page, paginationComponent] = usePagination(tickets, 5);

  return (
    <Segment>
      {tickets.length > 0 ? (
        <>
          {tickets.length > itemsPerPage && paginationComponent}
          <List divided relaxed>
            {page.map((ticket) => (
              <DashboardTicketEntity
                entity={ticket}
                user={user}
                key={ticket.id}
              />
            ))}
          </List>
          <Divider />
        </>
      ) : (
        <p style={{ fontStyle: "italic" }}>
          {t("dashboard.no.signals.paragraph")}
        </p>
      )}
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    tickets: state.ticket.entities || [],
    entity: {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDependencies: (params) => (dispatch) =>
        Promise.all([dispatch(fetchTickets(params))]),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSignalsList);

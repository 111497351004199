import React from "react";
import styled from "styled-components";
import { Button, Label, Icon } from "semantic-ui-react";
import { toggleUpvote } from "../../redux/modules/ticket";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const StyledVote = styled.div`
  float: right;
`;

export const UpvoteEntityComponent = ({ entity, toggleUpvote, user }) => {
  return (
    <StyledVote>
      <Button
        as="div"
        labelPosition="right"
        size="mini"
        disabled={entity.status === "resolved"}
        onClick={() => toggleUpvote(entity.id, entity.current_user_voted)}
      >
        <Button icon size="mini" primary={entity.current_user_voted}>
          <Icon name="thumbs up" />
        </Button>
        <Label as="a" basic pointing="left" size="mini">
          {entity.upvotes_count}
        </Label>
      </Button>
    </StyledVote>
  );
};

export const UpvoteEntity = connect(
  (state) => {
    return {
      user: state.auth.user,
    };
  },
  (dispatch) => bindActionCreators({ toggleUpvote }, dispatch)
)(UpvoteEntityComponent);

// @flow

type PermissionDescriptionItem = {
  title: string,
  description: ?string,
};

type PermissionDescription = {
  [key: string]: PermissionDescriptionItem,
};

// New permissions are added here and in backend
export const permissions: { [key: string]: string } = {
  controlPanelView: "uvh:control-panel:view",
  billingAccountAdd: "uvh:billing-account:add",
  billingAccountEdit: "uvh:billing-account:edit",
  billingAccountView: "uvh:billing-account:view",
  billingAccountDelete: "uvh:billing-account:delete",
  billingAccountViewAtDisposal: "uvh:billing-account:full-at-disposal",
  billingAccountViewBalance: "uvh:billing-account:view-balance",
  billingAttributeAdd: "uvh:billing-attribute:add",
  billingAttributeEdit: "uvh:billing-attribute:edit",
  billingModelAdd: "uvh:billing-model:add",
  billingModelEdit: "uvh:billing-model:edit",
  billingReportView: "uvh:billing-report:view",
  billingReportEdit: "uvh:billing-report:edit",
  billingSettlementDelete: "uvh:billing-settlement:delete",
  billingTransactionView: "uvh:billing-transaction:view",
  billingTransactionCreate: "uvh:billing-transaction:create",
  billingTransactionEdit: "uvh:billing-transaction:edit",
  billingTransactionDelete: "uvh:billing-transaction:delete",
  billingConfirmCollection: "uvh:billing:confirm-collection",
  blockAdd: "uvh:block:add",
  blockEdit: "uvh:block:edit",
  boardEdit: "uvh:board:edit",
  boardEditPost: "uvh:board:edit-post",
  chargeAdd: "uvh:charge:add",
  chargeDelete: "uvh:charge:delete",
  collectionGlobalView: "uvh:collection:view-global",
  financeGlobalView: "uvh:finance:view-global",
  customerEdit: "uvh:customer:edit",
  customerView: "uvh:customer:view",
  estateCustomerAdd: "uvh:estate-customer:add",
  estateCustomerDelete: "uvh:estate-customer:delete",
  estateEdit: "uvh:estate:edit",
  estateDelete: "uvh:estate:delete",
  estateGlobalView: "uvh:estate:view-global",
  estateSettleAmount: "uvh:estate:settle-amount",
  managementBulkImport: "uvh:management:bulk-import",
  noteEdit: "uvh:note:edit",
  notesView: "uvh:notes:view",
  teamAdd: "uvh:team:add",
  teamDelete: "uvh:team:delete",
  teamEdit: "uvh:team:edit",
  teamView: "uvh:team:view",
  ticketEdit: "uvh:ticket:edit",
  ticketGlobalView: "uvh:ticket:view-global",
};

export const permissionDescriptions: PermissionDescription = {
  [permissions.controlPanelView]: {
    title: "Преглед на контолен панел",
  },
  [permissions.billingAccountAdd]: {
    title: "Добавяне на каса",
  },
  [permissions.billingAccountEdit]: {
    title: "Редакция на каса",
  },
  [permissions.billingAccountDelete]: {
    title: "Изтриване на каса",
  },
  [permissions.billingAccountView]: {
    title: "Преглед на каса",
  },
  [permissions.billingAccountViewAtDisposal]: {
    title: "Преглед на разполагаемост на каса",
  },
  [permissions.billingAccountViewBalance]: {
    title: "Преглед на салдо на каса",
  },
  [permissions.billingAttributeAdd]: {
    title: "Добавяне на атрибут за таксуване",
  },
  [permissions.billingAttributeEdit]: {
    title: "Редакция на атрибут за таксуване",
  },
  [permissions.billingModelAdd]: {
    title: "Добавяне на модел за таксуване",
  },
  [permissions.billingModelEdit]: {
    title: "Редакция на модел за таксуване",
  },
  [permissions.billingReportView]: {
    title: "Преглед на отчет",
  },
  [permissions.billingReportEdit]: {
    title: "Промяна на отчет (заключване, отключване)",
  },
  [permissions.billingSettlementDelete]: {
    title: "Изтриване на плащане",
  },
  [permissions.billingTransactionCreate]: {
    title: "Добавяне на трансацкия",
  },
  [permissions.billingTransactionDelete]: {
    title: "Изтриване на трансакция",
  },
  [permissions.billingTransactionView]: {
    title: "Преглед на трансацкии",
  },
  [permissions.billingTransactionEdit]: {
    title: "Промяна на трансацкия",
  },
  [permissions.billingConfirmCollection]: {
    title: "Потвърждаване на плащане",
  },
  [permissions.blockAdd]: {
    title: "Добавяне на обект",
  },
  [permissions.blockEdit]: {
    title: "Редакция на обект",
  },
  [permissions.boardEdit]: {
    title: "Редакция на виртуално табло",
  },
  [permissions.boardEditPost]: {
    title: "Редакция на съобщения във виртуално табло",
  },
  [permissions.chargeAdd]: {
    title: "Добавяне на задължение",
  },
  [permissions.chargeDelete]: {
    title: "Изтриване на задължение",
  },
  [permissions.collectionGlobalView]: {
    title: "Преглед на секция Събирания",
  },
  [permissions.financeGlobalView]: {
    title: "Преглед на финансов обзор",
  },
  [permissions.customerEdit]: {
    title: "Редакция на клиентски профил",
  },
  [permissions.customerView]: {
    title: "Преглед на клиенти",
  },
  [permissions.estateCustomerAdd]: {
    title: "Добавяне на клиент към имот",
  },
  [permissions.estateCustomerDelete]: {
    title: "Премахване на клиент от имот",
  },
  [permissions.estateEdit]: {
    title: "Редакция на имот",
  },
  [permissions.estateDelete]: {
    title: "Изтриване на имот",
  },
  [permissions.estateGlobalView]: {
    title: "Преглед на всички управлявани имоти",
  },
  [permissions.estateSettleAmount]: {
    title: "Добавяне на плащане към имот",
  },
  [permissions.managementBulkImport]: {
    title: "Групова редакция",
  },
  [permissions.noteEdit]: {
    title: "Редакция на бележка",
  },
  [permissions.notesView]: {
    title: "Преглед на бележки",
  },
  [permissions.teamAdd]: {
    title: "Добавяне на акаунт в екипа",
  },
  [permissions.teamDelete]: {
    title: "Премахване на акаунт от екипа",
  },
  [permissions.teamEdit]: {
    title: "Редакция на акаунтите в екипа",
  },
  [permissions.teamView]: {
    title: "Преглед на акаунтите в екипа",
  },
  [permissions.ticketEdit]: {
    title: "Редакция на сигнал",
  },
  [permissions.ticketGlobalView]: {
    title: "Преглед на всички сигнали",
  },
};

import styled from "styled-components";
import React, { Component } from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import { logEntryTitle, MAP_PARAMS_TO_VALUE_LABEL_PAIRS } from "../helpers";
import dayjs from "dayjs";
import { reverse, routes } from "../../../../routes";
import { Link } from "react-router-dom";
import { t } from "../../../../constant/translations";

const ContentWrap = styled.div`
  line-height: 1.1em;
`;

export type ModalProps = {
  entry: Object,
};

export type ModalState = {
  modalOpen: Boolean,
};

const DetailsButton = (props) => {
  return <Button {...props}>{t("management.see")}</Button>;
};

export class DetailsModal extends Component<ModalProps, ModalState> {
  state = { isOpen: false };

  handleClose = () => this.setState({ isOpen: false });
  handleOpen = () => this.setState({ isOpen: true });

  renderParamsTable(entry) {
    const responseValueLabelPairs = Object.entries(
      MAP_PARAMS_TO_VALUE_LABEL_PAIRS
    )
      .filter(([p]) => entry.response[p])
      .map(([p, f]) => f(entry.response[p]));
    let body;
    body = (
      <Table.Row>
        {responseValueLabelPairs.map(({ label, value }) => {
          if (typeof value === "object") {
            value = Object.values(value).map((v) => {
              return (
                <Table.Cell key={v} collapsing={true}>
                  {v}
                </Table.Cell>
              );
            });
          }
          return (
            <Table.Cell key={label} collapsing={true}>
              {value}
            </Table.Cell>
          );
        })}
      </Table.Row>
    );

    return (
      <Table celled striped fixed singleLine>
        <Table.Header>
          <Table.Row>
            {responseValueLabelPairs.map(({ label }) => (
              <Table.HeaderCell key={label}>{label}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{body}</Table.Body>
      </Table>
    );
  }

  render() {
    let trigger = <DetailsButton onClick={this.handleOpen} />;
    const { entry } = this.props;

    return (
      <Modal
        open={this.state.isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        closeIcon={"close"}
        trigger={trigger}
      >
        <Modal.Header>
          {dayjs(entry.created_at).format("YYYY-MM-DD HH:mm")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Header>
            {
              <Link
                to={reverse(routes.teamDetails, {
                  userId: entry.user.id,
                })}
              >
                {entry.user.name}
              </Link>
            }
          </Modal.Header>
          <Modal.Description>
            {logEntryTitle(entry)}
            {this.renderParamsTable(entry)}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

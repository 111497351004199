import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Button,
  Comment,
  CommentGroup,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";

import { Avatar } from "../../components/common/avatar";
import { TicketEdit } from "./edit";
import { UserFile } from "./user-file";
import { TicketEditComment } from "./edit-comment";
import { ErrorWrap } from "../../helpers/error";
import { BackButton } from "../common/back-button";
import { RoleRequired } from "../auth/role-required";
import { ConfirmModalAction } from "../../components/common/confirm-modal";

import { deleteTicket, getTicket } from "../../redux/modules/ticket";
import {
  createTicketComment,
  deleteTicketComment,
  fetchTicketComments,
  updateTicketComment,
} from "../../redux/modules/ticketComment";

import { ROLE_ADMIN, ROLE_STAFF } from "../../config";
import { t } from "../../constant/translations";
import styled from "styled-components";
import { FilePreview } from "../common/file-preview";
import { Markdown } from "../../components/common/markdown";
import { permissions } from "../../constant/permissions";
import { UpvoteEntity } from "./ticket-upvote";

const StyledComment = styled(Comment)`
  clear: both;
  min-height: 60px;
`;

function TicketDetails({
  match,
  fetchDeps,
  deleteTicket,
  createTicketComment,
  updateTicketComment,
  deleteTicketComment,
  profiles,
  currentUser,
  tickets,
  ticketsLoading,
  ticketsError,
  ticketComments,
  ticketCommentsLoading,
  ticketCommentsError,
}) {
  const [commentFiles, setCommentFiles] = useState([]);
  const ticketId = parseInt(match.params.ticketId);

  useEffect(() => {
    fetchDeps(ticketId);
  }, [fetchDeps, ticketId]);

  const handleAttachCommentFiles = (_, { value }) => {
    setCommentFiles(value);
  };

  const handleSubmitComment = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const content = data.get("comment").trim();

    if (!content) return;
    e.target.reset();

    setCommentFiles([]);
    createTicketComment({
      ticket: ticketId,
      files: commentFiles,
      content,
    });
  };

  if (!tickets || ticketsLoading) {
    return <Loader active />;
  }

  const ticket = tickets.find((t) => t.id === ticketId);
  if (!ticket) return null;

  const { author, assignee } = ticket;

  const renderTicket = () => (
    <Fragment>
      <Header
        as="h2"
        subheader={t(`ticketTypes.${ticket.ticket_type}`)}
        content={ticket.title}
      />
      <Segment>
        <Markdown content={ticket.description} />
      </Segment>
      {ticket.files.map((file) => (
        <FilePreview file={file} key={file.id} />
      ))}
    </Fragment>
  );

  const renderComments = () => {
    if (!ticketComments) {
      return <Loader active />;
    }

    return (
      <Fragment>
        {ticketComments.length === 0 && (
          <Message content={t("ticket.no_comments")} />
        )}
        <CommentGroup>
          {ticketComments.map((comment) => {
            const author = comment.author;
            const actions = (
              <Fragment>
                <Comment.Action>
                  <TicketEditComment
                    entity={comment}
                    customTrigger={{ text: t("userActions.edit") }}
                  />
                </Comment.Action>
                <Comment.Action>
                  <ConfirmModalAction
                    header={t("ticket.delete_comment")}
                    content={
                      <Fragment>
                        {t("ticket.confirm_deletion")}:
                        <br />
                        {comment.content}
                      </Fragment>
                    }
                    error={ticketCommentsError}
                    loading={ticketCommentsLoading}
                    action={() => deleteTicketComment(comment)}
                    confirmLabel={t("userActions.delete")}
                    useTextTrigger={true}
                    textTriggerText={t("userActions.delete")}
                  />
                </Comment.Action>
              </Fragment>
            );

            return (
              <StyledComment key={comment.id}>
                <Avatar
                  as={Comment.Avatar}
                  emailHash={author.email_hash}
                  src={author.profile_picture}
                />
                <Comment.Content>
                  <Comment.Author as="a">{author.name}</Comment.Author>{" "}
                  {ticket.assignee === comment.author && (
                    <u>({t("ticketUserTypes.assignee").toLowerCase()})</u>
                  )}
                  {comment.deleted_at ? (
                    <Comment.Metadata>
                      {t("ticket.comment_deleted")}
                    </Comment.Metadata>
                  ) : (
                    <Fragment>
                      <Comment.Metadata>
                        {dayjs(comment.created_at).fromNow()}{" "}
                        <div>
                          {dayjs(comment.updated_at).diff(
                            dayjs(comment.created_at),
                            "minutes"
                          ) > 1 && <b>({t("labels.edited").toLowerCase()})</b>}
                        </div>
                      </Comment.Metadata>
                      <Comment.Text>
                        <p>{comment.content}</p>
                        {comment.files.map((file) => (
                          <div key={file.uuid}>
                            <FilePreview file={file} />
                          </div>
                        ))}
                      </Comment.Text>
                      <Comment.Actions>
                        {author.id === currentUser.id ? (
                          actions
                        ) : (
                          <RoleRequired
                            staff={true}
                            permission={permissions.ticketEdit}
                          >
                            {actions}
                          </RoleRequired>
                        )}
                      </Comment.Actions>
                    </Fragment>
                  )}
                </Comment.Content>
              </StyledComment>
            );
          })}
        </CommentGroup>
        {author.id === currentUser.id ||
        currentUser.role === ROLE_STAFF ||
        currentUser.role === ROLE_ADMIN ? (
          <Form
            reply
            onSubmit={handleSubmitComment}
            loading={ticketCommentsLoading}
            error={ticketCommentsError.exists()}
          >
            {ticketCommentsError.exists() && (
              <Message
                error
                header={t("error.error_occurred")}
                content={ticketCommentsError.getGlobal()}
              />
            )}
            <Form.TextArea name="comment" />
            <Form.Field>
              <UserFile
                name="files"
                value={commentFiles}
                onChange={handleAttachCommentFiles}
              />
            </Form.Field>
            <Button
              content={t("userActions.respond")}
              labelPosition="left"
              icon="edit"
              primary
            />
          </Form>
        ) : null}
      </Fragment>
    );
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column largeScreen={4} computer={6} tablet={16}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <BackButton />
                {author.id === currentUser.id && ticket.state !== "open" ? (
                  <TicketEdit
                    entity={ticket}
                    trigger_props={{ floated: "right" }}
                  />
                ) : (
                  <RoleRequired
                    staff={true}
                    permission={permissions.ticketEdit}
                  >
                    <TicketEdit
                      entity={ticket}
                      trigger_props={{ floated: "right" }}
                    />
                  </RoleRequired>
                )}
                <Segment.Group>
                  <Segment>
                    <Header
                      as="h4"
                      content={assignee.name}
                      subheader={t("ticketUserTypes.assignee")}
                    />
                    <Header
                      as="h4"
                      content={author.name}
                      subheader={t("labels.author")}
                    />
                  </Segment>
                </Segment.Group>
                <RoleRequired userOnly={true}>
                  <UpvoteEntity entity={ticket} />
                </RoleRequired>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column largeScreen={12} computer={10} tablet={16}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>{renderTicket()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" dividing>
                  {t("labels.comments")}
                </Header>
                {renderComments()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  block: state.blocks.selected.id,
  profiles: state.profile.entities,
  currentUser: state.auth.user,
  estateCustomers: state.estatecustomers.entities,

  tickets: state.ticket.entities,
  ticketsLoading: state.ticket.pending,
  ticketsError: ErrorWrap.fromError(state.ticket.error),

  ticketComments: state.ticketComment.entities,
  ticketCommentsLoading: state.ticketComment.pending,
  ticketCommentsError: ErrorWrap.fromError(state.ticketComment.error),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDeps: (ticketId) => (dispatch) =>
        Promise.all([
          dispatch(getTicket(ticketId)),
          dispatch(fetchTicketComments(ticketId)),
        ]),
      deleteTicket,
      createTicketComment,
      updateTicketComment,
      deleteTicketComment,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketDetails));

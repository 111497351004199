/* @flow */
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  GridColumn as Col,
  Image,
  Loader,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { UserEdit } from "./edit";
import { RoleRequired } from "../../auth/role-required";
import { ConfirmModalAction } from "../../../components/common/confirm-modal";
import { ErrorWrap } from "../../../helpers/error";

import { t } from "../../../constant/translations";
import { reverse, routes } from "../../../routes";
import type User from "../../../redux/modules/user";
import {
  deleteFromTeam,
  fetchUsers,
  removeActiveSessions,
} from "../../../redux/modules/user";
import { filterByProp } from "../../../redux/restdux/utils";
import { getGravatarSrc } from "../../../components/common/avatar";
import { Inline } from "../../../components/base/elements";
import { permissions } from "../../../constant/permissions";
import { withRouter } from "react-router";
import { fetchUserRoles } from "../../../redux/modules/user-role";
import { getSearchQ, SearchInput } from "../../../components/common/search";
import { filter } from "../../../helpers/filter";

type TeamListProps = {
  users: ?Array<User>,
  error: Object,
  loading: boolean,
  fetchUsers: Function,
  deleteUser: Function,
};

const InlineText = styled.span`
  flex-grow: 1;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 34px;
`;

const InlineImage = styled(Image)`
  min-width: 35px;
  vertical-align: middle;
  margin: auto;
`;

const ActionList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 80px;
  justify-content: space-around;
`;

const StyledHeader = styled.div`
  margin: 0 0 1em 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSearchInput = styled(SearchInput)`
  height: 32px !important;
  width: 300px;
`;

const RightActions = styled.div``;

const TeamListTable = ({ props }) => {
  const { activeUsers, loading, error, deleteFromTeam } = props;
  const q = getSearchQ();

  const filteredUsers =
    filter(activeUsers, q, ["full_name", "email", "role_v2"]) || [];

  const rows = filteredUsers.map((u, idx) => {
    return (
      <Table.Row key={`user-${idx}`}>
        <Table.Cell collapsing={true}>
          {u.email && (
            <Link
              to={reverse(routes.teamDetails, {
                userId: u.id,
              })}
            >
              <Inline>
                <InlineImage>
                  <Image
                    src={
                      u.profile_picture
                        ? u.profile_picture.url
                        : getGravatarSrc(u.email)
                    }
                    size={"mini"}
                    rounded={true}
                  />
                </InlineImage>
                <InlineText>{u.email}</InlineText>
              </Inline>
            </Link>
          )}
        </Table.Cell>
        <Table.Cell>
          {u.first_name} {u.last_name}
        </Table.Cell>
        <Table.Cell collapsing={true}>
          {u.role_v2 || t(`roles.${u.role}`)}
        </Table.Cell>
        <Table.Cell collapsing={true}>
          <ActionList>
            <RoleRequired admin={true} permission={permissions.teamEdit}>
              <UserEdit entity={u} />
            </RoleRequired>
            <RoleRequired admin={true} permission={permissions.teamDelete}>
              {!u.is_root_account && (
                <Fragment>
                  <ConfirmModalAction
                    header={t("management.logout_all_devices")}
                    content={t("management.confirm_logout_devices")}
                    trigger_props={{
                      color: "blue",
                      size: "tiny",
                      icon: "log out",
                    }}
                    error={error}
                    loading={loading}
                    action={() => props.removeActiveSessions(u)}
                    confirmLabel={t("labels.yes")}
                  />
                  <ConfirmModalAction
                    header={`${t("management.deactivate_user")} ${
                      u.first_name
                    } ${u.last_name}`}
                    content={`${t("management.confirm_user_deactivation")} ${
                      u.first_name
                    } ${u.last_name}(${u.email})`}
                    trigger_props={{
                      color: "red",
                      icon: "delete",
                      size: "tiny",
                    }}
                    error={error}
                    loading={loading}
                    action={() => deleteFromTeam(u)}
                    confirmLabel={t("management.deactivate")}
                  />
                </Fragment>
              )}
            </RoleRequired>
          </ActionList>
        </Table.Cell>
      </Table.Row>
    );
  });
  return <Table.Body>{rows}</Table.Body>;
};

class TeamListComponent extends Component<TeamListProps> {
  componentDidMount() {
    if (!this.props.users) {
      this.props.fetchUsers();
    }
    this.props.fetchUserRoles();
  }

  render() {
    const { users, error, loading, history } = this.props;

    if (error.exists()) {
      return <div>{error.getGlobal()}</div>;
    }

    if (!users) {
      return <Loader active />;
    }

    const activeUsers = filterByProp(users, { is_active: true });
    activeUsers.sort((a, b) => {
      return a.is_root_account ? -1 : 0;
    });
    return (
      <Grid centered padded>
        <Col mobile={16} textAlign={"left"}>
          <StyledHeader>
            <StyledSearchInput
              replace={true}
              placeholder={t("management.search_email_name_role")}
            />
            <RightActions>
              <RoleRequired admin={true} permission={permissions.teamEdit}>
                <Button
                  basic={true}
                  onClick={() => history.push(reverse(routes.teamRoles))}
                  size={"tiny"}
                >
                  {t("management.roles")}
                </Button>
              </RoleRequired>
              <Button
                basic={true}
                onClick={() => history.push(reverse(routes.teamAudit))}
                size={"tiny"}
              >
                {t("management.action_history")}
              </Button>
              <RoleRequired admin={true} permission={permissions.teamAdd}>
                <UserEdit
                  trigger_props={{
                    content: t("management.new_user"),
                  }}
                />
              </RoleRequired>
            </RightActions>
          </StyledHeader>
          <Table celled={true} striped={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("labels.user")}</Table.HeaderCell>
                <Table.HeaderCell>{t("labels.name")}</Table.HeaderCell>
                <Table.HeaderCell>{t("resources.user-role")}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <TeamListTable
              props={{
                activeUsers,
                error,
                loading,
                deleteFromTeam: this.props.deleteFromTeam,
                removeActiveSessions: this.props.removeActiveSessions,
              }}
            />
          </Table>
        </Col>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchUsers, deleteFromTeam, fetchUserRoles, removeActiveSessions },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.user.entities,
    error: ErrorWrap.fromError(state.user.error),
    loading: state.user.pending,
  };
};

export const TeamList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamListComponent));

import { Grid } from "semantic-ui-react";
import { AuditTopMenu } from "./top-menu";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { ContentFilterPanel } from "../../../../components/common/content-filter";
import { AuditList } from "./list";
import { auditQueryFilterParams, ContentFilterForm } from "./content-filter";
import { bindActionCreators } from "redux";
import { determinePage } from "../../../../components/common/page-navigation";

type AuditPageProps = {
  meta: Object,
  fetchDeps: Function,
};

class AuditPageComponent extends Component<AuditPageProps> {
  render() {
    let [next, previous] = determinePage(this.props.meta);
    return <Page previous={previous} next={next} />;
  }
}

const Page = ({ previous, next }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  return (
    <Grid centered padded style={{ height: "100%" }}>
      <Grid.Row>
        <Grid.Column mobile={16} textAlign={"left"}>
          <AuditTopMenu
            openFilter={() => setFilterOpen(!filterOpen)}
            next={next}
            previous={previous}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ height: "inherit" }}>
        <Grid.Column mobile={16} textAlign={"left"}>
          <ContentFilterPanel
            open={filterOpen}
            onApply={() => setFilterOpen(false)}
            queryParamConfig={auditQueryFilterParams}
            FormBody={ContentFilterForm}
          >
            <AuditList />
          </ContentFilterPanel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    meta: state.auditLog.meta || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const AuditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditPageComponent);
